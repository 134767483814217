import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import { HeadingAndTextProps } from './models';

import './HeadingAndText.scss';

const HeadingAndText: FC<HeadingAndTextProps> = ({ module }) => (
  <section
    className={classNames('title-text', {
      'title-text--grey': module.isBackgroundGrey,
      'rte-white': !module.isBackgroundGrey,
      'rte-light-grey': module.isBackgroundGrey,
    })}
    data-testid="heading-and-text"
  >
    <Container>
      <Row>
        <Col xl="5" md="4" sm="12" className="title-text__title-content col--no-gutters">
        <h2 className="title-text__title h1">{module.heading}</h2>
        </Col>
        <Col xl="7" md="8" sm="12" className="title-text__text-content col--no-gutters">
          <div
            className={classNames('title-text__rte rte', {
              'title-text__rte--no-margin': !module.ctaLink[0],
            })}
            dangerouslySetInnerHTML={{ __html: module.description }}
          />
          <LinkItem
            link={module.ctaLink}
            linkClass="title-text__link btn btn--pink"
            titleClass="title-text__link-text"
            hasIcon
            isLink
            showTitle
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default HeadingAndText;
